import { Routes, Route, Link, useNavigate } from "react-router-dom";
import "./App.css";
import Form from "../Form/Form";
import { Login } from "../Login/Login";
import { useEffect, useState } from "react";

import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../components/Loading/Loading";
////import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
//import 'antd/dist/antd.min.css'

import AllActions from "../../store/actions";
import { Homepage } from "../Homepage/Homepage";

import logo from "../../assets/images/ybtlogosmall.png"; // Tell webpack this JS file uses this image
import { LanguageSwitcher } from "../../components/LanguageSwitcher/LanguageSwitcher";

import { MenuOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { useTranslate } from "../../components/Translate";
import { PdfViewer } from "../PdfViewer/PdfViewer";
import { useMediaQuery } from "react-responsive";

import { Dropdown, Menu, Space } from "antd";
import { Delivery } from "../Delivery/Delivery";

import { useLocation } from "react-router-dom";
import { QrCode } from './../QrCode/QrCode';




const NotFound = () => {

const navigate = useNavigate()

  useEffect(() => {
navigate('/')
  }, []);

  return (
  <div>
    <h1 className="error404">404</h1>;
  </div>
  )
};


function App() {
  const dispatch = useDispatch();
  const { locale } = useTranslate();

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    //console.log("LoADUSER");
    dispatch(AllActions.userActions.loadUser());
  }, []);

  const logOut = () => {
    console.log("LOG_OUT");
    dispatch(AllActions.userActions.logOut());
  };

  const { userLoaded, loggedIn, usingQrcode } = useSelector((state) => state.currentUser);

   console.log("ROOTNAV usingQrcode:", usingQrcode);

  if (!userLoaded) {
    return <Loading />;
  }





  const menu = () => {
    let itemss = [{
      label: (
        <Link to={""} className="headerbtnlink" onClick={logOut}>
          <p className="headercontacts font-dm-medium">{locale.logout}</p>
        </Link>
      ),
      key: "2",
    }];
    return <Menu items={itemss} />;
  };

  
  //Appname
  document.title = 'YBT Oy - '+locale.panel_name;



  return (
    <div className="App">
      <BrowserRouter>

      {(loggedIn) &&
        <div className="header">
          <img
            className="headerlogo"
            src={logo}
            alt="YBT Oy"
            onClick={() => {
              //window.open("https://www.ybt.fi/", "_blank");
              window.location = '/'
            }}
            href="someLink"
          />
{/*           <LanguageSwitcher additionalClasses={"header-language"} />
 */}
          {!isTabletOrMobile ? (
            <div className="header-btns">


              <Link to={""} className="headerbtnlink" onClick={logOut}>
                <p className="headercontacts logoutbtn font-dm-medium">
                  {locale.logout}
                </p>
              </Link>
            </div>
          ) : (
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              visible={menuVisible}
              onVisibleChange={(visible) => {
                setMenuVisible(visible);
              }}
              placement="bottomRight"
            >
              {menuVisible ? (
                <MenuFoldOutlined className="header-dropdown" />
              ) : (
                <MenuOutlined className="header-dropdown" />
              )}
            </Dropdown>
          )}
        </div>    
        }

        <Routes>
          loggedIn && <Route path="/" element={loggedIn ? <Homepage /> : <Login />} />
          {loggedIn && <Route path="/delivery/:id" element={<Delivery />} />}
          {usingQrcode && <Route path="/delivery-qr/:qrcode" element={<Delivery />} /> }
          {(loggedIn || usingQrcode) && <Route path="/pdfviewer/:id" element={<PdfViewer />} /> }
          <Route path="/qrcode/:qrcode" element={<QrCode />} />
{/*           {!loggedIn && <Route path="*" element={<Login />} />}
 */}         
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );


}

export default App;
