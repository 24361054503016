import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../components/Loading/Loading";
import AllActions from "../../store/actions";
import "./Login.css";

import { LockOutlined, UserOutlined, ScanOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input } from "antd";
import { Trans, useTranslate } from "../../components/Translate";
import { LanguageSwitcher } from "../../components/LanguageSwitcher/LanguageSwitcher";
import { BiQrScan } from "react-icons/bi";
import Html5QrcodePlugin from "../../components/QrCodeReader";
import { useNavigate } from 'react-router-dom';

export const Login = ({ setToken }) => {
  const dispatch = useDispatch();
  const { locale } = useTranslate();

  const [isLoading, setisLoading] = useState(false);
  const { response_type, loggingIn } = useSelector(
    (state) => state.currentUser
  );

  const [alertMessage, setAlertMessage] = useState(false);

  const [showScanner, setShowScanner] = useState(false);
  const navigate = useNavigate();



  useEffect(() => {
    console.log("Loginscreen lanched");
    //document.location.replace('asd')
  }, []);

  useEffect(() => {
    console.log("Loginscreen response_type", response_type);
    console.log("erroralert", response_type);
    if (response_type === "error") {
      setAlertMessage(true);
    } else {
      setAlertMessage(false);
    }
  }, [response_type]);

  if (isLoading) {
    return <Loading />;
  }

  const handleSubmit = async (values) => {
    console.log("handleSubmit 1");
    if (loggingIn) return;
    console.log("Received values of form: ", values);

    dispatch(
      AllActions.userActions.getUser({
        username: values.username,
        password: values.password,
      })
    );
  };

  const qrCodeSuccessCallback = (decodedText, decodedResult) => {
    /* handle success */
    console.log("qrCodeSuccessCallback", decodedText, decodedResult);

    if (decodedText) {
      console.log("HIDE SCANNER");
      setShowScanner(false);

      var part = decodedText.substring(decodedText.lastIndexOf('/') + 1);
      console.log("lastpart", part);
      navigate(`/qrcode/${part}`);
    }
  };

  return (
    <>
      <div className="login-wrapper">
        <div className="login-image-wrapper"></div>

        <div className="login-form-wrapper">
          <div className="login-form-content">
            <h1 className="login-header font-itc">{locale.slogan}</h1>

            <p className="login-appname font-dm-regular">{locale.panel_name}</p>

            {!showScanner && (
              <>
                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{
                    remember: true,
                    username: "mika@ybt.fi",
                    password: "",
                  }}
                  onFinish={handleSubmit}
                >
                  {alertMessage && (
                    <p className="errorText">{locale.loginerror}</p>
                  )}

                  <Form.Item
                    name="username"
                    rules={[{ required: true, message: locale.insertusername }]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder={locale.username}
                      style={{ borderRadius: "10px" }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: locale.insertpassword }]}
                  >
                    <Input.Password
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder={locale.password}
                      style={{ borderRadius: "10px" }}
                    />
                  </Form.Item>

                  {/* 
              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Muista minut</Checkbox>
                </Form.Item>
              </Form.Item>
              */}

{/*                   <div className="form-languageselector">
                    <LanguageSwitcher />
                  </div> */}

                  <div className="">
                    <p>
                      {locale.termstext} <a href="">{locale.terms}</a>{" "}
                      {locale.and}
                      {"  "}
                      <a>{locale.privacy}</a>
                    </p>
                  </div>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                      {loggingIn ? locale.loginbtnprogress : locale.loginbtn}
                    </Button>
                  </Form.Item>

                  <p>- {locale.or} -</p>

                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    onClick={() => {
                      setShowScanner(true);
                    }}
                  >
                    {locale.scan_qr}
                  </Button>
                </Form>
              </>
            )}

            {/*           {!showScanner &&
              <BiQrScan size={'10em'} onClick={() => {setShowScanner(true)}}/>
            } */}

            {showScanner && (
              <>
                {
                  <div className="scan-wrapper">
                    <Html5QrcodePlugin
                      fps={10}
                      qrbox={250}
                      disableFlip={false}
                      qrCodeSuccessCallback={qrCodeSuccessCallback}
                      stopScanning={() => {
                        setShowScanner(false);
                      }}
                    />
                  </div>
                }

                <p
                  onClick={() => {
                    setShowScanner(false);
                  }}
                >
                  Peruuta
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
