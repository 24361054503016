import userActions from "./userActions";

const AllActions = {
  userActions,
};

export default AllActions;



