let languages = {
    ['fi'] : {
        factory_1: 'Ylitornio',
        factory_2: 'Raahe',
        factory_3: 'Kuhmo',
        finland:'Fi',
        sweden:'Swe',

        panel_name: 'Toimittajaportaali',

        slogan:'Parasta Betonista',
        deliveries:'Toimitukset',
        wait_check: 'Odottaa kuittausta',
        wait_delivery: 'Odottaa toimitusta',
        delivered: 'Toimitettu',



        loginerror:'Käyttäjätunnus tai salasana väärin!',
        login: 'Kirjaudu sisään',
        loginbtn: 'Kirjaudu sisään',
        loginbtnprogress: 'Kirjaudutaan...',
        logout: 'Kirjaudu ulos',
        username: 'Käyttäjätunnus',
        password: 'Salasana',
        insertusername: 'Syötä käyttäjätunnus!',
        insertpassword: 'Syötä salasana!',
        termstext: 'Käyttäessäsi palvelua hyväksyt palvelun',
        terms: 'Käyttöehdot',
        and: 'ja',
        privacy: 'Tietosuojakäytännöt.',
        or: 'Tai',
        scan_qr: 'Skannaa QR koodi',

        ordered: 'Tilattu',
        blueprints: 'Piirustuksia',
        prepared: 'Valmistettu',
        booked: 'Toimitettu',



        give_km:'Anna kuljetusmatka (km)',
        give_arrivetime:'Anna saapumisaika',
        give_unloadtime:'Anna purkuaika',

        huom: 'Huomitoitavaa',
        deliveryaddress:'Toimitusosoite',
        receiver:'Vastaanottaja',
        car:'Auto',
        km:'Kuljetusmatka',
        arrivetime:'Saapumisaika',
        unloadtime:'Purkuaika',
        driversign:'Autonkuljettajan kuittaus',
        receiversign:'Vastaanottajan kuittaus',

        
        tunnus: 'Tunnus',
        id: 'ID',
        pituus: 'Pituus',
        korkeus: 'Korkeus',
        paksuus: 'Paksuus',
        m2: 'm2',
        paino: 'Paino',
        asennusjärjestys: 'Asennusjärjestys',
        järjestys:'Järjestys',
        varasto: 'Varasto',

        kpl:'kpl',
        tonnia: 'tonnia',

        clear: 'Tyhjennä',
        save: 'Tallenna',
    },
    ['swe'] : {
        factory_1: 'Övertorneå',
        factory_2: 'Brahestad',
        factory_3: 'Kuhmo',
        finland:'Fi',
        sweden:'Swe',

        panel_name: 'Leveransportal',

        slogan:'Det bästa från Beton',
        deliveries:'Leveranser',

        wait_check: 'Väntar på bekräftelse',
        wait_delivery: 'Väntar på leverans',
        delivered: 'Levereras',

        loginerror:'Felaktigt användarnamn eller lösenord!',
        login: 'Logga in',
        loginbtn: 'Logga in',
        loginbtnprogress: 'Loggar in...',
        logout: 'Logga ut',
        username: 'Användarnamn',
        password: 'Lösenord',
        insertusername: 'Skriv in ditt användarnamn',
        insertpassword: 'Ange lösenordet!',
        termstext: 'Genom att använda tjänsten accepterar du tjänsten',
        terms: 'Villkor',
        and: 'och ',
        privacy: 'Integritetspolicy.',
        or: 'Och',
        scan_qr: 'Skanna qr-koden',

        ordered: 'Bokad',
        blueprints: 'Ritningar',
        prepared: 'Beredd',
        booked: 'Levereras',


        give_km:'Ge transportresa (km)',
        give_arrivetime:'Ge din ankomsttid',
        give_unloadtime:'Ge lossningstid',

        huom: 'Anmärkningsvärd',
        deliveryaddress:'Leveransadress',
        receiver:'Mottagare',
        car:'Bil',
        km:'Transportresa',
        arrivetime:'Ankomst tid',
        unloadtime:'Demonteringstid',
        driversign:'Föraren signatur',
        receiversign:'Mottagaren signatur',

        tunnus: 'Objekt',
        id: 'ID',
        pituus: 'längd',
        korkeus: 'Höjd',
        paksuus: 'tjocklek',
        m2: 'm2',
        paino: 'Vikt',
        asennusjärjestys: 'Installationsordning',
        järjestys:'ordning',
        varasto: 'Lagret',

        kpl:'st',
        tonnia: 'ton',

        clear: 'Klar',
        save: 'Spara',
    },
}

export default languages