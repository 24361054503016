import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { api, apiSecure } from "../../components/api";
import { Loading } from "../../components/Loading/Loading";
import AllActions from "../../store/actions";
import "./Delivery.css";

import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Form,
  Input,
  TimePicker,
} from "antd";
import { Trans, translate, useTranslate } from "../../components/Translate";
import { useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {
  formatTime,
  getParsedDate,
  getParsedTime,
} from "../../components/globalFunctions";
import { ModalSignatureCanvas } from "../../components/ModalSignatureCanvas/ModalSignatureCanvas";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { PdfButton } from "./../../components/PdfButton/PdfButton";
import {encode} from "base64-arraybuffer"
import moment, { locale } from "moment";
import 'moment-timezone'

const SignatureCaptureInput = ({
  signature,
  setSignature,
  canOpen,
  onClick,
  onHide,
  isModalOpen,
}) => {
  return (
    <>
      <div className="signature-box"  onClick={() => {
        if (canOpen) onClick()
        }}>
        {signature && <img className="" src={signature} />}
      </div>

      {/*       {signee && <div className="blockquote-footer mb-2">{signee}</div>} */}

      {isModalOpen && (
        <ModalSignatureCanvas
          widthRatio={1}
          onHide={onHide}
          displayNameInput
          setSignature={setSignature}
        />
      )}
    </>
  );
};

const LogModalTrip = ({ text, setText, canOpen }) => {
  const [inputtext, setInputText] = useState(text || 0);
  const [logVisible, setLogVisible] = useState(false);
  const { locale } = useTranslate();

  return (
    <>
      {text ? (
        <p
          onClick={() => {
            if(canOpen) setLogVisible(true);
          }}
        >
          {text} km
        </p>
      ) : (
        <>
        {canOpen &&
          <button
            className="logbtn font-dm-medium"
            onClick={() => {
              setLogVisible(true);
            }}
          >
            Kirjaa
          </button>
          }
        </>
      )}

      <CustomModal
        visible={logVisible}
        setVisible={() => {
          if(canOpen) setLogVisible(false);
        }}
      >
        <p>{locale.give_km}</p>

        <input
          type="number"
          name="postal"
          placeholder="km"
          value={inputtext}
          onChange={(event) => {
            setInputText(event.target.value);
          }}
        />

        <br/>

        <button
          className="savebtn font-dm-medium"
          onClick={() => {
            setLogVisible(false);
            setText(inputtext);
          }}
        >
           {locale.save}
        </button>
      </CustomModal>
    </>
  );
};

const LogModalArrivetime = ({ arriveTime, setArriveTime,canOpen }) => {
  const [logVisible, setLogVisible] = useState(false);
  const [value, setValue] = useState();
  const { locale } = useTranslate();

  return (
    <>
      {arriveTime ? (
        <p
          onClick={() => {
            if(canOpen) setLogVisible(true);
          }}
        >
          {getParsedTime(arriveTime)}
        </p>
      ) : (
        <>
        {canOpen &&
        <button
          className="logbtn font-dm-medium"
          onClick={() => {
            setLogVisible(true);
          }}
        >
          Kirjaa
        </button>
}</>
      )}

      <CustomModal
        visible={logVisible}
        setVisible={() => {
          setLogVisible(false);
        }}
      >
        <p>{locale.give_arrivetime}</p>

        <TimePicker
          format="HH:mm"
          value={value}
          inputReadOnly={true}
          onChange={(time) => {
            let newTime = moment(time).utc()
            console.log('changed:', time, newTime)
            setValue(time);
          }}
        />
        
        <br/>

        <button
          className="savebtn font-dm-medium"
          onClick={() => {
            setLogVisible(false);

            console.log('setting', value)

            let isoString = moment(value).add(3, 'hours').toISOString()
            setArriveTime(isoString);
          }}
        >
           {locale.save}
        </button>
      </CustomModal>
    </>
  );
};

const LogModalUnloadTime = ({
  unloadStart,
  setUnloadStart,
  unloadEnd,
  setUnloadEnd,
  canOpen
}) => {
  const [logVisible, setLogVisible] = useState(false);
  const [value1, setValue1] = useState();
  const [value2, setValue2] = useState();
  const { locale } = useTranslate();

  console.log('VALUE1', value1, unloadStart)
  console.log('VALUE2', value2, unloadEnd)

  return (
    <>
      {unloadStart && unloadEnd ? (
        <p
          onClick={() => {
            if(canOpen) setLogVisible(true);
          }}
        >
         {/*  {unloadStart + "-" + unloadEnd + '\n'} */}
          {getParsedTime(unloadStart) + "-" + getParsedTime(unloadEnd)}
        </p>
      ) : (
        <>
        {canOpen &&
        <button
          className="logbtn font-dm-medium"
          onClick={() => {
            setLogVisible(true);
          }}
        >
          Kirjaa
        </button>
}</>
      )}

      <CustomModal
        visible={logVisible}
        setVisible={() => {
          setLogVisible(false);
        }}
      >
        <p>{locale.give_unloadtime}</p>

        <TimePicker.RangePicker
          format="HH:mm"
          value={[value1, value2]}
          inputReadOnly={true}
          onChange={(time) => {
            console.log("time:", time);
            setValue1(time[0])
            setValue2(time[1]) 
          }}
        />

<br/>

        <button
          className="savebtn font-dm-medium"
          onClick={() => {
            setLogVisible(false);
            setUnloadStart(moment(value1).add(3, 'hours').toISOString());
            setUnloadEnd(moment(value2).add(3, 'hours').toISOString());
          }}
        >
           {locale.save}
        </button>
      </CustomModal>
    </>
  );
};

const RenderListRow = ({ data = {}, isMobile=false }) => {
  /*    console.log('RenderListRow', data, itemsInDroppable) */

  return (
    <div className={`project-p-table-values`}>
      <p className="table-first font-dm-regular">{data.Tunnus}</p>
      <p className="font-dm-regular">{data.ValmistusNro}</p>

      {!isMobile &&
      <>
      <p className="font-dm-regular">{data.Pituus}</p>
      <p className="font-dm-regular">{data.Korkeus}</p>
      <p className="font-dm-regular">{data.Paksuus}</p>
      </>
      }
      <p className="font-dm-regular">{data.M2?.toFixed(1)}</p>
      <p className="font-dm-regular">{data.Paino?.toFixed(1) + " t"}</p>
      <p className="font-dm-regular">{data.KuormanAsennusJarjestys}</p>
      <p className="font-dm-regular">
        {" "}
        {(data.VarastoPaikkaPilari || "") +
          (data.VarastoPaikkaPilari && data.VarastoPaikkaVali ? " - " : "") +
          (data.VarastoPaikkaVali || "")}
      </p>
    </div>
  );
};

export const Delivery = ({ setToken }) => {
  const [isLoading, setisLoading] = useState(false);
  const [deliveryData, setDeliveryData] = useState({});
  const { locale } = useTranslate();
  const navigate = useNavigate();
  let { id, qrcode } = useParams();

  console.log('PARAMS', id, qrcode)

  const { tehtaat } = useSelector((state) => state.currentUser.user);
  const [sign1Open, setSign1Open] = useState(false);
  const [signature1, setSign1] = useState(null);

  const [sign2Open, setSign2Open] = useState(false);
  const [signature2, setSign2] = useState(null);

  const [tripKm, setTripKm] = useState(null);
  const [arriveTime, setArriveTime] = useState(null);
  const [unloadStart, setUnloadStart] = useState(null);
  const [unloadEnd, setUnloadEnd] = useState(null);


  const isTabletOrMobile = useMediaQuery({ maxWidth: 1080 });


  console.log('isTabletOrMobile', isTabletOrMobile)

  const arrayBufferToBase64 = buffer => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };



  useEffect(() => {
    if (deliveryData?.tiedot) {
      if(deliveryData?.tiedot?.AllekirjoitusKuljettaja) {
        const base64String = encode(deliveryData.tiedot.AllekirjoitusKuljettaja.data)
        let basestring = "data:image/png;base64,"+base64String
        setSign1(basestring)
      } 

      if(deliveryData?.tiedot?.AllekirjoitusVastaanottaja) {
        const base64String = encode(deliveryData.tiedot.AllekirjoitusVastaanottaja.data)
        let basestring = "data:image/png;base64,"+base64String
        setSign2(basestring)
      }

      if(deliveryData?.tiedot?.ToteutunutMatka) {
        setTripKm(deliveryData?.tiedot?.ToteutunutMatka)
      }

      if(deliveryData?.tiedot?.ToteutunutPerilla) {
        console.log('arrivetime found', deliveryData?.tiedot?.ToteutunutPerilla)
        setArriveTime(deliveryData?.tiedot?.ToteutunutPerilla)
      }

      if(deliveryData?.tiedot?.ToteutunutPurkuAlku) {
        console.log('unloadstart found', deliveryData?.tiedot?.ToteutunutPurkuAlku)
        setUnloadStart(deliveryData?.tiedot?.ToteutunutPurkuAlku)
      }

      if(deliveryData?.tiedot?.ToteutunutPurkuLoppu) {
        console.log('unloadend found', deliveryData?.tiedot?.ToteutunutPurkuLoppu)
        setUnloadEnd(deliveryData?.tiedot?.ToteutunutPurkuLoppu)
      }
    }
  }, [deliveryData]);


  const getDelivery = () => {
    console.log("getDelivery", id);
    setisLoading(true);
    console.log("getDelivery");

    let req_config = {
      method: "POST",
      url: `/toimittaja_getDelivery`,
      data: {
        deliveryId: id,
        qrcode:qrcode
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        setDeliveryData(response.data.data);
        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };

  useEffect(() => {
    getDelivery();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (!deliveryData.tiedot) {
    return (
      <>
        <p>Ei tietoa</p>
        <button onClick={getDelivery}>{"HAE KULJETUS"}</button>
        <button onClick={() => navigate(-1)}>Go back</button>
      </>
    );
  }



  const saveDelivery = () => {
    console.log("saveDelivery");
    setisLoading(true);

    let req_config = {
      method: "POST",
      url: `/toimittaja_saveDelivery`,
      data: {
        deliveryId: id,
        qrcode: qrcode,
        tripKm:tripKm,
        arriveTime:arriveTime,
        unloadStart: unloadStart,
        unloadEnd:unloadEnd,
        signature1: signature1,
        signature2: signature2,
      },
    };

    console.log(req_config)

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        getDelivery();
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      }); 

  }



  const OpenMaps = (lat, lng) => {
    //console.log('openmaps, ', lat+' '+lng )
    var newlat = lat.replace(/,/g, ".");
    var newlng = lng.replace(/,/g, ".");

    let newurl = "https://www.google.com/maps/place/" + newlat + "," + newlng;
    window.open(newurl, "_blank");
  };

  let typeColor = "black";

  if (
    new Date() > new Date(deliveryData.tiedot.ToimitusPVM) &&
    !deliveryData.tiedot.Kuitattu
  )
    typeColor = "#CD1414";
  else if (
    new Date() <= new Date(deliveryData.tiedot.ToimitusPVM) &&
    !deliveryData.tiedot.Kuitattu
  )
    typeColor = "#CDAF14";
  else if (deliveryData.tiedot.Kuitattu) typeColor = "#14DC40";

  return (
    <>
      <div className="delivery-content">
        <h1 className="delivery-header font-itc">
          {deliveryData.tiedot.TyokohdeNimi}
        </h1>

        {console.log(deliveryData)}



        <div>
          <div className="deliveryheader-headeritems">


            <div className="leftitems">
              <div className="item">
                <p className="value font-dm-bold" style={{ color: typeColor }}>
                {deliveryData.tiedot.KuormakirjaNumero}
                </p>
              </div>

              <div className="item">
                <p className="value font-dm-regular">
                {deliveryData.tiedot.TehdasNimi}
                </p>
              </div>

  


            </div>

            <div className="item">
              <p className="value font-dm-bold" style={{ color: typeColor }}>
                {deliveryData.tiedot.KPL || 0}
              </p>
              <p className="label font-dm-regular">{locale.kpl}</p>
            </div>

            <div className="item">
              <p className="value font-dm-bold" style={{ color: typeColor }}>
                {deliveryData.tiedot.Paino?.toFixed(0) || 0}
              </p>
              <p className="label font-dm-regular">{locale.tonnia}</p>
            </div>

            <div className="item date">
              <p className="value font-dm-bold" style={{ color: typeColor }}>
                {/* {getParsedDate(item.ToimitusPVM) || 0} */}
                {getParsedDate(deliveryData.tiedot.ToimitusPVM)}
              </p>
            </div>

            <div className="item time">
              <p className="value font-dm-bold" style={{ color: typeColor }}>
                {/* {getParsedDate(item.ToimitusPVM) || 0} */}
                {getParsedTime(deliveryData.tiedot.PurkuKlo)}
              </p>
            </div>

            <div className="item pdf">
            <PdfButton
                url="toimittaja_getDeliveryPdf"
                data={{
                  deliveryId: deliveryData.tiedot.KuormakirjaNumero,
                  qrcode: qrcode,
                  projectId: deliveryData.tiedot.TyokohdeNro,
                  useSqlLocale: true,
                }}
              />
            </div>

          </div>
        </div>

        <div className="deliverybase">
          <div className="project-p-table">
            <div className="project-p-table-headers">
              <p className="table-first font-dm-bold">{locale.tunnus}</p>
              <p className="font-dm-bold">{locale.id}</p>

              {!isTabletOrMobile && 
              <>
              <p className="font-dm-bold">{locale.pituus}</p>
              <p className="font-dm-bold">{locale.korkeus}</p>
              <p className="font-dm-bold">{locale.paksuus}</p>
              </>
            }
              <p className="font-dm-bold">{locale.m2}</p>
              <p className="font-dm-bold">{locale.paino}</p>
              <p className="font-dm-bold">{!isTabletOrMobile ? locale.asennusjärjestys : locale.järjestys}</p>
              <p className="font-dm-bold">{locale.varasto}</p>
            </div>

            {deliveryData.elementit &&
              deliveryData.elementit.length >= 1 &&
              deliveryData.elementit.map((item2, index2) => {
                return <RenderListRow data={item2} isMobile={isTabletOrMobile} />;
              })}

            <div className="project-p-table-footers">
              <p className="table-first font-dm-bold">
                {(deliveryData?.elementit?.length || 0) + " "+ locale.kpl}
              </p>
              <p className="font-dm-bold"></p>
              {!isTabletOrMobile && 
              <>
              <p className="font-dm-bold"></p>
              <p className="font-dm-bold"></p>
              <p className="font-dm-bold"></p>
              </>}

              <p className="font-dm-bold">
                {/*                 {(item.Neliot?.toFixed(1) || 0) + " m2"}
                 */}{" "}
              </p>
              <p className="font-dm-bold">
                {(deliveryData.tiedot.Paino?.toFixed(1) || 0) + " t"}
              </p>
              <p className="font-dm-bold"></p>
              <p className="font-dm-bold"></p>
            </div>
          </div>

          <div className="delivery-gray-area">
            <p className="font-dm-bold" style={{ margin: 0 }}>
              {locale.huom}
            </p>
            <p className="font-dm-medium" style={{ margin: 0 }}>
              {
                "Nostoelimet mukaan kuormaan, vastaanottajalle soitto ennen työmaalle saapumista"
              }
            </p>
          </div>

          <div className="delivery-gray-area">
            <div className="item">
              <p className="label font-dm-bold">{locale.deliveryaddress}:</p>
              <p
                className={`value ${
                  deliveryData.tiedot.lat && deliveryData.tiedot.lng && "link"
                } font-dm-medium`}
                onClick={() => {
                  if (deliveryData.tiedot.lat && deliveryData.tiedot.lng)
                    OpenMaps(deliveryData.tiedot.lat, deliveryData.tiedot.lng);
                }}
              >
                {deliveryData.tiedot.Toimitusosoite1 +
                  ", " +
                  deliveryData.tiedot.Toimitusosoite2}
              </p>
            </div>

            <div className="item">
              <p className="label font-dm-bold">{locale.receiver}:</p>
              <p className="value font-dm-medium">
                {deliveryData.tiedot.Vastaanottaja}
              </p>
            </div>

            <div className="item">
              <p className="label font-dm-bold">{locale.car}:</p>
              <p className="value font-dm-medium">
                {deliveryData.tiedot.AutonNimi}
              </p>
            </div>

            <div className="item">
              <p className="label font-dm-bold">{locale.km}: </p>
              <p className="value font-dm-medium">
                <LogModalTrip text={tripKm} setText={setTripKm} canOpen={!deliveryData.tiedot.Kuitattu} />
              </p>
            </div>
          </div>

          <div className="delivery-gray-area">
            {/*         <div className="item">
              <p className="label font-dm-bold">Lähtöaika tehtaalta:</p>
              <p className="value font-dm-medium"></p>
            </div> */}
            <div className="item">
              <p className="label font-dm-bold">{locale.arrivetime}:</p>
              <p className="value font-dm-medium">
                <LogModalArrivetime
                  arriveTime={arriveTime}
                  setArriveTime={setArriveTime}
                  canOpen={!deliveryData.tiedot.Kuitattu}
                />
              </p>
            </div>
            <div className="item">
              <p className="label font-dm-bold">{locale.unloadtime}: </p>
              <p className="value font-dm-medium">
                <LogModalUnloadTime
                  unloadStart={unloadStart}
                  setUnloadStart={setUnloadStart}
                  unloadEnd={unloadEnd}
                  setUnloadEnd={setUnloadEnd}
                  canOpen={!deliveryData.tiedot.Kuitattu}
                />
              </p>
            </div>
          </div>

          <div className="delivery-gray-area">
            <div className="signature-container">
              <div className="signature-item">
                <p className="label font-dm-bold">{locale.driversign}:</p>

                <SignatureCaptureInput
                  canOpen={!deliveryData.tiedot.Kuitattu}
                  signature={signature1}
                  setSignature={setSign1}
                  onClick={() => {
                    console.log("setSign1 Open");
                    setSign1Open(true);
                  }}
                  onHide={() => {
                    console.log("setSign1 Close");
                    setSign1Open(false);
                  }}
                  isModalOpen={sign1Open}
                />

{!deliveryData.tiedot.Kuitattu &&

                <button
                  className="signature-clearbtn font-dm-medium"
                  onClick={() => {
                    setSign1(null);
                  }}
                >
                  {locale.clear}
                </button>
}
              </div>

              <div className="signature-item">
                <p className="label font-dm-bold">{locale.receiversign}:</p>

                <SignatureCaptureInput
                  canOpen={!deliveryData.tiedot.Kuitattu}
                  signature={signature2}
                  setSignature={setSign2}
                  onClick={() => {
                    console.log("setSign2 Open");
                    setSign2Open(true);
                  }}
                  onHide={() => {
                    console.log("setSign2 Close");
                    setSign2Open(false);
                  }}
                  isModalOpen={sign2Open}
                />

                  {!deliveryData.tiedot.Kuitattu &&
                <button
                  className="signature-clearbtn font-dm-medium"
                  onClick={() => {
                    setSign2(null);
                  }}
                >
                  {locale.clear}
                </button>
                }
              </div>
            </div>
          </div>

          {!deliveryData.tiedot.Kuitattu &&
            <button className="savebtn font-dm-medium" onClick={() => saveDelivery()}>{locale.save}</button>
          }

        </div>
      </div>
    </>
  );
};
