import "./ModalSignatureCanvas.css";
import React, { useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import SignatureCanvas from "react-signature-canvas";
import Modal from "react-modal";

import Resizer from "react-image-file-resizer";
import { useTranslate } from "../Translate";


function resizedataURL(datas, wantedWidth, wantedHeight){
  return new Promise(async function(resolve,reject){

      // We create an image to receive the Data URI
      var img = document.createElement('img');

      // When the event "onload" is triggered we can resize the image.
      img.onload = function()
      {        
          // We create a canvas and get its context.
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');

          // We set the dimensions at the wanted size.
          canvas.width = wantedWidth;
          canvas.height = wantedHeight;

          // We resize the image with the canvas method drawImage();
          ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

          var dataURI = canvas.toDataURL();

          // This is the return of the Promise
          resolve(dataURI);
      };

      // We put the Data URI in the image's src attribute
      img.src = datas;

  })
}// Use it like : var newDataURI = await resizedataURL('yourDataURIHere', 50, 50);

export const ModalSignatureCanvas = ({ setSignature, onHide, widthRatio }) => {
  const [signatureResult, setSignatureResult] = useState("");
  const sigCanvas = useRef({});
  const sigPad = useRef({});
  const { locale } = useTranslate();

  console.log("ModalSignatureCanvas");

  const setSignatureOnChange = () => {
    const dataURL = sigCanvas.current.toDataURL();
    setSignatureResult(dataURL);
  };

  const saveInput = async () => {
    let image = await resizedataURL(signatureResult, 240, 90);
    console.log("image:", image);

    onHide();
    setSignature(image);
  };

  const clearInput = () => {
    sigPad.current.clear();
    setSignatureResult("");
  };

  const measuredRef = useCallback(
    (node) => {
      const resizeCanvas = (signaturePad, canvas) => {
        console.log("clientWidth", canvas.parentElement.clientWidth);
        console.log("clientHeight", canvas.parentElement.clientHeight);
        canvas.width = canvas.parentElement.clientWidth; // width of the .canvasWrapper
        canvas.height = canvas.parentElement.clientHeight;
        signaturePad.clear();
      };

      window.addEventListener("orientationchange", function () {
        if (node !== null) {
          sigCanvas.current = node.getCanvas();
          sigPad.current = node.getSignaturePad();
          resizeCanvas(node.getSignaturePad(), node.getCanvas());
        }
      });

      if (node !== null) {
        sigCanvas.current = node.getCanvas();
        sigPad.current = node.getSignaturePad();
        resizeCanvas(node.getSignaturePad(), node.getCanvas());
      }
    },
    [widthRatio]
  );

  const isSignatureValid = !!signatureResult;
  const isFormValid = isSignatureValid;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <>
      <Modal
        isOpen={true}
        onRequestClose={onHide}
        className="canvasModal"
        /* style={customStyles} */
        ariaHideApp={false}
      >
        <div className="canvasWrapper">
          <SignatureCanvas
            ref={measuredRef}
            onEnd={setSignatureOnChange}
           /*  backgroundColor={"red"} */
          />
        </div>

        <div className="canvasBtns">
          <button
            type="button"
            className="btn"
            onClick={clearInput}
          >
            {locale.clear}
          </button>
          <button
            type="button"
            className="btn"
            onClick={saveInput}
            disabled={!isFormValid}
          >
            {locale.save}
          </button>
        </div>
      </Modal>
    </>
  );
};
