import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from 'react';
import { Loading } from './../../components/Loading/Loading';
import AllActions from './../../store/actions/index';
import { useDispatch, useSelector } from "react-redux";
import { useState } from 'react';


export const QrCode = ({ setToken }) => {
  let { qrcode } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();

  const { usingQrcode, QrCode } = useSelector((state) => state.currentUser);

  useEffect(() => {
      console.log('QrCode', qrcode)
      setisLoading(true)

      dispatch(
        AllActions.userActions.validateQrCode({
          qrcode: qrcode,
        })
      ); 
    }, []);
    

  useEffect(() => {
    if (QrCode) {
      console.log('QrCode Updated', QrCode)
      navigate(`/delivery-qr/${QrCode}`);  
    }
  }, [QrCode]);



  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <h1>TERVE {qrcode}</h1>
    </>
  );


};
