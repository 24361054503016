import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import userReducer from "./reducers/userReducer";

const store = configureStore({
  reducer: {
    currentUser: userReducer,
  },
  middleware: [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  ],
});

export default store;
