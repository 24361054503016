import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { api, apiSecure } from "../../components/api";
import { Loading } from "../../components/Loading/Loading";
import AllActions from "../../store/actions";
import "./Homepage.css";

import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Form, Input } from "antd";
import { Trans, translate, useTranslate } from "../../components/Translate";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { getParsedDate } from "../../components/globalFunctions";

import { PdfButton } from "../../components/PdfButton/PdfButton";
import { getParsedTime } from './../../components/globalFunctions';

const ListItem = ({ data, typeColor }) => {
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const navigate = useNavigate();

  const { tehtaat } = useSelector((state) => state.currentUser.user);

  const openDelivery = (id) => {
    console.log("openDelivery", id);
    navigate(`/delivery/${id}`);
  };

  return (
    <>
      <div key={data.id} className="deliverylist-item">
        <div
          className="deliverylist-inside"
          onClick={() => {
            openDelivery(data.KuormakirjaNumero);
          }}
        >
          <div className="deliverylist-header">
            <p className="name font-itc">{data.TyokohdeNimi}</p>

            <p className="factory font-dm-regular">
              {/* {tehtaat[item.Tehdas] && tehtaat[item.Tehdas].name} */}
              <a
                className="font-dm-bold"
                style={{ color: typeColor, marginRight: "0.5rem" }}
              >
                {data.KuormakirjaNumero}
              </a>{" "}
              {tehtaat[data.Tehdas] && tehtaat[data.Tehdas].name}
            </p>
          </div>

          <div className="deliverylist-headeritems">
            <div className="item">
              <p className="value font-dm-bold" style={{ color: typeColor }}>
                {data.KPL || 0}
              </p>
              <p className="label font-dm-regular">kpl</p>
            </div>

            <div className="item">
              <p className="value font-dm-bold" style={{ color: typeColor }}>
                {data.Paino?.toFixed(0) || 0}
              </p>
              <p className="label font-dm-regular">tonnia</p>
            </div>

            <div className="item date">
              <p className="value font-dm-bold" style={{ color: typeColor }}>
                {/* {getParsedDate(item.ToimitusPVM) || 0} */}
                {getParsedDate(data.ToimitusPVM) || 0}
              </p>
            </div>

            <div className="item time">
              <p className="value font-dm-bold" style={{ color: typeColor }}>
                {/* {getParsedDate(item.ToimitusPVM) || 0} */}
                {getParsedTime(data.PurkuKlo)}
              </p>
            </div>

          {console.log(data)}

            <div className="item pdf">
              <PdfButton
                url="toimittaja_getDeliveryPdf"
                data={{
                  deliveryId: data.KuormakirjaNumero,
                  projectId: data.TyokohdeNro,
                  useSqlLocale: true,
                }}
              />
            </div>


          </div>
        </div>
      </div>
    </>
  );
};

export const Homepage = ({ setToken }) => {
  const [isLoading, setisLoading] = useState(false);
  const [deliverys, setDeliverys] = useState([]);
  const { locale } = useTranslate();
  const navigate = useNavigate();

  const getProjects = () => {
    setisLoading(true);
    console.log("getProjects");

    let req_config = {
      method: "GET",
      url: `/toimittaja_getDeliverys`,
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        setDeliverys(response.data.data);
        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };

  useEffect(() => {
    getProjects();
    //console.log("useEffect called");
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  const openProject = (id) => {
    console.log("openProject2", id);
    console.log("TEST: ", `/project/${id}`);
    navigate(`/project/${id}`);
  };

  let waitdata = [
    {
      id: 112332,
      name: "As Oy Oulun Aurinkoranta",
      kpl: 20,
      t: 32,
      pvm: "28.8.2022",
      time: "7:00",
    },
    {
      id: 23212,
      name: "As Oy Oulun Aurinkoranta",
      kpl: 5,
      t: 7,
      pvm: "1.8.2022",
      time: "15:00",
    },
  ];

  return (
    <>
      <div className="homepage-content">
        <h1 className="homepage-header font-itc">{locale.deliveries}</h1>

        <div>
          <p className="classHeader font-dm-bold">{locale.wait_check}</p>

          {deliverys.map((item, index) => {
            if ((new Date() > new Date(item.ToimitusPVM)) && !item.Kuitattu) {
              return <ListItem key={index} data={item} typeColor={"#CD1414"} />;
            }
          })}
        </div>

        <div>
          <p className="classHeader font-dm-bold">{locale.wait_delivery}</p>
          {deliverys.map((item, index) => {
            if ((new Date() <= new Date(item.ToimitusPVM)) && !item.Kuitattu) {
              return <ListItem key={index} data={item} typeColor={"#CDAF14"} />;
            }
          })}
        </div>

        <div>
          <p className="classHeader font-dm-bold">{locale.delivered}</p>

          {deliverys.map((item, index) => {
            if (item.Kuitattu) {
              return <ListItem key={index} data={item} typeColor={"#14DC40"} />;
            }
          })}
        </div>
      </div>
    </>
  );
};
