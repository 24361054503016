import "./CustomModal.css";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Modal from "react-modal";


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const CustomModal = ({
  visible,
  setVisible,
  children
}) => {

  //Scrolldisable
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [visible])

  return (
    <>
      <Modal
        isOpen={visible}
        onRequestClose={setVisible}
        style={customStyles}
        ariaHideApp={false}
      >
        {children}
      </Modal>
    </>
  );
};

