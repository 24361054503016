import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../components/api";
import { formatdata } from "../../components/globalFunctions";
import { Loading } from "../../components/Loading/Loading";
import "./Form.css";

function FormItem({ data = null, setData = () => {}, label = "", index = 0 }) {
  return (
    <>
      <div>
        <h4>{label}</h4>

        <div className="checkboxContainer">
          <input
            id={index + "valuenull"}
            name={index + "valuenull"}
            type="checkbox"
            checked={data === null}
            onChange={() => setData(null)}
          />
          <label htmlFor={index + "valuenull"}>Ei tietoa</label>

          <input
            id={index + "value4"}
            name={index + "value4"}
            type="checkbox"
            checked={data === 4}
            onChange={() => setData(4)}
          />
          <label htmlFor={index + "value4"}>4</label>

          <input
            id={index + "value5"}
            name={index + "value5"}
            type="checkbox"
            checked={data === 5}
            onChange={() => setData(5)}
          />
          <label htmlFor={index + "value5"}>5</label>

          <input
            id={index + "value6"}
            name={index + "value6"}
            type="checkbox"
            checked={data === 6}
            onChange={() => setData(6)}
          />

          <label htmlFor={index + "value6"}>6</label>

          <input
            id={index + "value7"}
            name={index + "value7"}
            type="checkbox"
            checked={data === 7}
            onChange={() => setData(7)}
          />

          <label htmlFor={index + "value7"}>7</label>

          <input
            id={index + "value8"}
            name={index + "value8"}
            type="checkbox"
            checked={data === 8}
            onChange={() => setData(8)}
          />

          <label htmlFor={index + "value8"}>8</label>

          <input
            id={index + "value9"}
            name={index + "value9"}
            type="checkbox"
            checked={data === 9}
            onChange={() => setData(9)}
          />

          <label htmlFor={index + "value9"}>9</label>

          <input
            id={index + "value10"}
            name={index + "value10"}
            type="checkbox"
            checked={data === 10}
            onChange={() => setData(10)}
          />

          <label htmlFor={index + "value10"}>10</label>
        </div>
      </div>
    </>
  );
}

const Form = () => {
  let { id } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const [formData, setFormData] = useState([]);

  const [topic1, setTopic1] = useState(null);
  const [topic2, setTopic2] = useState(null);
  const [topic3, setTopic3] = useState(null);
  const [topic4, setTopic4] = useState(null);
  const [topic5, setTopic5] = useState(null);
  const [topic6, setTopic6] = useState(null);
  const [topic7, setTopic7] = useState(null);
  const [topic8, setTopic8] = useState(null);
  const [topic9, setTopic9] = useState(null);
  const [topic10, setTopic10] = useState(null);
  const [topic11, setTopic11] = useState(null);
  const [topic12, setTopic12] = useState(null);

  const [huom, setHuom] = useState('');
  const [addr, setAddr] = useState('');
  const [postal, setPostal] = useState('');


  //console.log("ID:", id);

  const getFormdata = () => {
    let req_config = {
      method: "POST",
      url: `/getFormdata`,
      data: {
        formId: id,
      },
    };

    api(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        setFormData(response.data.data);
        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };

  useEffect(() => {
    getFormdata();
    console.log("useEffect called");
  }, []);


  const sendFormdata = () => {
    let req_config = {
      method: "POST",
      url: `/sendFormdata`,
      data: {
        formId: id,
        topic1: topic1,
        topic2: topic2,
        topic3: topic3,
        topic4: topic4,
        topic5: topic5,
        topic6: topic6,
        topic7: topic7,
        topic8: topic8,
        topic9: topic9,
        topic10: topic10,
        topic11: topic11,
        topic12: topic12,
        huom: formatdata(huom),
        addr: formatdata(addr),
        postal: formatdata(postal),
      },
    };

    console.log('SENDING:', req_config)

    api(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        //setFormData(response.data.data);
        //setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        //setisLoading(false);
      });
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!formData.tiedot) {
    return <h1>Lomaketta ei löytynyt</h1>;
  }

  return (
    <>
      <main>
        <h3>Linkki: {id}</h3>
        <h3>
          Nimi: {formData.tiedot.Yritys + " - " + formData.tiedot.Henkilo}
        </h3>
        <h3>Työkohteet:</h3>

        {formData.tyokohteet.length >= 1 &&
          formData.tyokohteet.map((item, index) => {
            return <h4 key={index}>{item.Nimi}</h4>;
          })}

        <br />

        <h2>{formData.tiedot.KyselyRyhma}</h2>

        <h4>
          Olemme toimittaneet teille betonituotteita kuluneen vuoden aikana.
          Laatujärjestelmämme olennaisena osana on seurata asiakkaidemme
          tyytyväisyyttä toimintaamme. Toteuttamme vuosittain
          laatujärjestelmämme mukaisen asiakastyytyväisyyskyselyn, jossa
          pyydämme palautetta toimintaamme 12 kysymyksellä asteikolla 4-10.
        </h4>

<hr />
        <div>
          <FormItem
            data={topic1}
            setData={setTopic1}
            index={1}
            label="1. Tarjous ja kaupanteko"
          />
          <FormItem
            data={topic2}
            setData={setTopic2}
            index={2}
            label="2. Informaation kulku"
          />
          <FormItem
            data={topic3}
            setData={setTopic3}
            index={3}
            label="3. Yhteistyön sujuvuus"
          />
          <FormItem
            data={topic4}
            setData={setTopic4}
            index={4}
            label="4. Aikataulujen pitävyys"
          />
          <FormItem
            data={topic5}
            setData={setTopic5}
            index={5}
            label="5. Autonkuljettajan toiminta työmaalla"
          />
          <FormItem
            data={topic6}
            setData={setTopic6}
            index={6}
            label="6. Yhteistyö toimituksen aikana"
          />
          <FormItem
            data={topic7}
            setData={setTopic7}
            index={7}
            label="7. Elementtien ulkonäkö"
          />
          <FormItem
            data={topic8}
            setData={setTopic8}
            index={8}
            label="8. Elementtien tekninen laatu"
          />
          <FormItem
            data={topic9}
            setData={setTopic9}
            index={9}
            label="9. Asennustyön suorittaminen (jos asennus kuului urakkaan)"
          />
          <FormItem
            data={topic10}
            setData={setTopic10}
            index={10}
            label="10. Jälkitöiden hoito"
          />
          <FormItem
            data={topic11}
            setData={setTopic11}
            index={11}
            label="11. Hinta/laatu -suhde"
          />
          <FormItem
            data={topic12}
            setData={setTopic12}
            index={12}
            label="12. Yleisarvosana toimituksesta"
          />
        </div>

        <br />

        <div>
          <label>
            Muut huomautukset ja kommentit:
            <br />
            <textarea  
              name="huom" 
              placeholder="Kerro mielipiteesi?" 
              defaultValue={addr} 
              onChange={(event) => {setHuom(event.target.value)}} 
            />

          </label>
        </div>

        <div>
          <h4>
            YBT Joulupaketti - Lähetämme jokaiselle kyselyyn vastanneelle
            asiakkaallemme YBT:n perinteisen joulupaketin. Täytä alle tietosi
            joulupaketin lähettämiseksi.
          </h4>

          <label>
            Osoite minne joulupaketti voidaan lähettää:
            <br />
            <input 
              type="text"
              name="address" 
              placeholder="Katuosoite 1" 
              value={addr} 
              onChange={(event) => {setAddr(event.target.value)}} 
            />
          </label>
          <br />
          <input 
              type="text"
              name="postal" 
              placeholder="00000 Paikkakunta" 
              value={postal} 
              onChange={(event) => {setPostal(event.target.value)}} 
            />
        </div>

          <button className="sendBtn" onClick={sendFormdata}>
            Lähetä
          </button>

        <br />
      </main>
    </>
  );
};

export default Form;
