import "./PdfButton.css";
import { FilePdfOutlined } from "@ant-design/icons";
import { apiSecure } from "../api";
import { useEffect, useState } from "react";

export function PdfButton({ url = "", data = {} }) {
  const [loadingPdf, setLoadingPdf] = useState("");
  const [showPdf, setShowPdf] = useState(false);

  console.log('PdfButton', url, data)

  const getPdf = () => {

    if (loadingPdf) return

    setLoadingPdf(true)

    console.log("getPdf");

    let req_config = {
      method: "POST",
      url: url,
      data: data,
      responseType: "blob",
      headers: {
        Accept: "application/pdf",
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log(response);
        const fileURL = window.URL.createObjectURL(response.data);
        window.open(fileURL);
        setLoadingPdf(false)
      })

      .catch((error) => {
        console.log("Error:", error);
        setLoadingPdf(false)
      });
  };



  return (
    <>
      <div
        className="pdf-btn"
        onClick={(event) => {
          getPdf();
          event.stopPropagation();
        }}
      >
        <FilePdfOutlined style={{ fontSize: 30 }} />
      </div>
    </>
  );
}
